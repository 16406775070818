<template>
  <div class="reportLayout layout" :class="{ closeLeft: closeLeft }">
    <headerComponent v-if="loadLayout" class="report-head" :headdata="headInfo" @getInfo="getLeftStatus"></headerComponent>
    <news class="news" system="bi"></news>
    <router-view v-if="loadLayout" :sdata="closeLeft" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import headerComponent from '@/components/headerComponent'
import watermark from '@/plugin/watermark.js'
import { getHomeInfo, getOrCreateProject, getCityWeather, getFestivals, getProjectMalls } from '@/api/apiV2_dashboard'
import news from '@/components/news.vue'
import BI_config from '@/utils/config'
export default {
  name: 'reportLayout',
  data() {
    return {
      basicInfo: null,
      headInfo: [],
      menus: {},
      loadLayout: false,
      isMobile: false,
      closeLeft: false, //菜单开关
    }
  },
  components: { headerComponent, news },
  computed: {
    ...mapState({
      defaultMenus: state => state.defaultMenus,
    }),
  },
  async created() {
    let projectID = await this.getProjectID()
    this.$store.commit('setProjectID', projectID)
    if (!projectID) {
      this.loadLayout = true
    } else {
      //获取天和节假日
      this.getWeatherAndFestivalData()
      await this.$commonJs._salejudge(this.$route)
      this.$set(this.menus, 'dashboards', this.defaultMenus.dashboards)
      this.$set(this.menus, 'customMenus', this.defaultMenus.customMenus)
      this.$set(this.menus, 'defaultMenus', this.defaultMenus.defaultMenus)
      if (this.menus.dashboards && this.menus.defaultMenus) {
        this.$store.commit('setMenus', this.menus)
      }
      this.isloadOK()
      getProjectMalls().then(v => {
        this.$store.commit('setProjectMalls', v.data)
      })
      //获取系统颜色
      getHomeInfo().then(v => {
        this.basicInfo = v.data
        let radioUnit = v.data.project.axisUnit
        this.$store.commit('setbasicInfo', v.data)
        this.$store.commit('setPageunit', radioUnit)
        this.$pageunit = radioUnit

        let themeColor = v.data.project.theme > 0 ? 'dark' : 'light'
        let mainColor = 'lan'
        switch (v.data.project.mainColor) {
          case 0:
            mainColor = 'lan'
            break
          case 1:
            mainColor = 'cheng'
            break
          case 2:
            mainColor = 'qing'
            break
          case 3:
            mainColor = 'hong'
            break
          case 4:
            mainColor = 'zi'
            break
        }
        window.document.documentElement.setAttribute('data-theme', themeColor)
        window.document.documentElement.setAttribute('data-high', mainColor)

        // 默认 开启水印
        if (!this.basicInfo.project.disableWatermark) {
          watermark.set(this.basicInfo.user.name, this.basicInfo.project.watermarkOpacity, this.basicInfo.project.theme)
        }

        //路由权限 没权限跳转404
        if (!this.$commonJs._point('BI_M0107')) {
          this.$router.push({ path: `${BI_config.dashboardProxy}/404` })
        }
        this.isloadOK()
      })
    }
  },
  methods: {
    init() {
      const ua = navigator.userAgent
      const ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
        isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
        isAndroid = ua.match(/(Android)\s+([\d.]+)/),
        isMobile = isIphone || isAndroid
      //判断
      if (isMobile) {
        this.isMobile = true
        document.getElementsByTagName('body')[0].className += ' mobilebody'
        this.$store.commit('setismobile', true)
      } else {
        if (!(window.navigator.userAgent.indexOf('Chrome') && window.chrome)) {
          if (!window.localStorage.getItem('judgeChrome')) {
            this.$confirm('为了最优的产品体验，建议您下载使用Chrome（谷歌）浏览器', {
              confirmButtonText: '去下载',
              cancelButtonText: '继续使用',
              showClose: false,
              closeOnClickModal: false,
            })
              .then(() => {
                window.open(
                  'https://www.baidu.com/s?wd=chrome%E6%B5%8F%E8%A7%88%E5%99%A8%E5%AE%98%E7%BD%91%E4%B8%8B%E8%BD%BD&rsv_spt=1&rsv_iqid=0xde4e974d0033f4b5&issp=1&f=3&rsv_bp=1&rsv_idx=2&ie=utf-8&rqlang=cn&tn=baiduhome_pg&rsv_enter=0&rsv_dl=ts_2&oq=chrome%25E6%25B5%258F%25E8%25A7%2588%25E5%2599%25A8%25E5%25AE%2598%25E7%25BD%2591%25E4%25B8%258B%25E8%25BD%25BD&rsv_t=f78eVFNyiubMLbInPXupZLRKQDzTm%2FbLruD2hd1Gx%2BgIosB5SAweCOvBtWrjII%2FGgD3G&rsv_btype=t&rsv_pq=d6bca8df006ae80a&prefixsug=chrome%25E6%25B5%258F%25E8%25A7%2588%25E5%2599%25A8%25E5%25AE%2598%25E7%25BD%2591%25E4%25B8%258B%25E8%25BD%25BD&rsp=2'
                )
              })
              .catch(() => {
                window.localStorage.setItem('judgeChrome', 'true')
              })
          }
        }
      }
    },
    isloadOK() {
      // 接口全部请求完成再跳转  因为跳转的时候会把没有请求完成的接口都取消
      if (this.menus.dashboards && this.menus.defaultMenus && this.basicInfo) {
        let defaultMenus = this.menus.defaultMenus
        //如果没有数据面板的访问权限，跳转到分析模型
        const dashboard = defaultMenus.find(x => {
          return x.code == 'dashboard'
        })
        const ppt = defaultMenus.find(x => {
          return x.code == 'ppt'
        })
        if (!dashboard && !ppt) {
          const analysis = defaultMenus.find(x => {
            return x.code == 'analysis'
          })
          if (analysis && analysis.children.length > 0) {
            window.location.href = analysis.href
          } else {
            const system = defaultMenus.find(x => {
              return x.code == 'system'
            })
            if (system && system.children.length > 0) {
              window.location.href = system.href
            }
          }

          return
        } else {
          let info = {
            custom: null,
            menus: null,
          }
          let custom = this.menus.customMenus[0]
          info.custom = custom
          info.menus = defaultMenus
          this.headInfo = info
        }

        this.loadLayout = true
      }
    },
    async getProjectID() {
      return this.$route.params.projectID
    },
    getWeatherAndFestivalData() {
      getCityWeather().then(res => {
        let weathers = {
          loading: false,
          data: [],
        }
        let set = new Set()
        res.data.forEach(item => {
          if (!set.has(item.d)) {
            var icon = item.s.split('/')[0]
            var temperatures = item.t.split('/')
            weathers.data.push([item.d, icon, temperatures[0], temperatures[1], item.w.split('/')[0]])
            set.add(item.d)
          }
        })
        this.$store.commit('setWeathers', weathers)
      })
      getFestivals().then(res => {
        let festivals = {
          loading: false,
          statutory: res.data.statutoryFestivals,
          custom: res.data.customFestivals,
        }
        this.$store.commit('setFestivals', festivals)
      })
    },
    getLeftStatus(e) {
      this.closeLeft = e.closeLeft
      this.headInfo = e
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style lang="scss">
.reportLayout {
  width: 100%;
  min-width: 1200px;
  min-height: 100%;
  .report-head {
    @include theme_bg1($theme-light);
    width: 100%;
    min-width: 1000px;
    height: 60px;
    position: fixed;
    z-index: 99;
  }
  &.closeLeft {
    .headerBox {
      min-width: 1200px;
      .logo {
        margin-left: -250px;
        transition: 0.3s ease-in-out;
      }
      .logo-r {
        width: 100%;
        transition: 0.3s ease-in-out;
      }
    }
    .reportPages {
      margin-left: 0;
      transition: 0.3s ease-in-out;
      .page-l {
        left: -250px;
        transition: 0.3s ease-in-out;
        .tree-box {
          .addbtn {
            left: -250px;
            transition: 0.3s ease-in-out;
          }
        }
      }
    }
  }
  .news {
    display: none;
  }
}
</style>
