<template>
  <div v-if="news && news.isShow && news.detailUrl" class="news">
    <div class="left">
      <span>
        <img src="../assets/images/news.png" alt />
        <p>{{ news.majorType }}</p>
        ：</span
      >
      <p>{{ news.title }}</p>
      <a :href="news.detailUrl" target="_blank">查看详情</a>
    </div>
    <i class="el-icon-close" @click="closeNews()"></i>
  </div>
</template>
<script>
import { getNews, closingNews } from '@/api/apiV2_dashboard'

export default {
  name: 'news',
  props: ['system'],
  data() {
    return {
      news: null,
    }
  },
  async created() {
    await this.getNews()
  },
  methods: {
    async getNews() {
      let res = await getNews({ project: this.system })
      if (res.data) {
        this.news = res.data
        this.$store.commit('setNews', this.news)
      }
    },
    async closeNews() {
      await closingNews(this.news.id)
      this.news = null
      this.$store.commit('setNews', this.news)
    },
  },
}
</script>
<style lang="scss" scoped>
.news {
  border: 1px solid #f1924e;
  border-radius: 2px;
  background: #fff5ed;
  height: 38px;
  padding: 9px;
  position: fixed;
  top: 75px;
  margin-left: 15px;
  width: calc(100% - 287px);
  transition: 0.3s ease-in-out;
  display: flex;
  align-items: center;
  i {
    cursor: pointer;
  }
  .left {
    flex: 1;
    display: flex;
    align-items: center;
    span {
      display: flex;
      align-items: center;
      img {
        margin-right: 5px;
      }
    }
    a {
      margin-left: 10px;
      color: #1676cd;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

[data-theme='dark'] .news {
  border: 1px solid rgba(255, 133, 0, 0.1);
  background: rgba(255, 133, 0, 0.1);
  .left {
    p {
      color: #ddd;
    }
  }
}
</style>
